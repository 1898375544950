import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { JwtModule } from '@auth0/angular-jwt';
// 3rd Party
import { ClarityModule } from '@clr/angular';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { environment } from './../environments/environment';
import { AppComponent } from './app.component';
// Routing Module
import { AppRoutingModule } from './app.routing';
// Layouts
import { BackendLayoutComponent } from './layout/backend-layout.component';
import { SimpleLayoutComponent } from './layout/simple-layout.component';
// Shared
import { AuthGuard } from './service/auth.guard';
// Model & Services
import { GlobalService } from './service/global.service';
import { ClientDataService } from './service/client-data.service';
import { ContractDataService } from './service/contract-data.service';
import { InvoiceDataService } from './service/invoice-data.service';
import { SettingDataService } from './service/setting-data.service';
import { StaffDataService } from './service/staff-data.service';
import { StaffService } from './service/staff.service';
import { UserDataService } from './service/user-data.service';
// other
import { P404Component } from './page/404.component';
import { SharedModule } from './shared/shared.module';

export function tokenGetter() {
  return localStorage.getItem(environment.tokenName);
}

@NgModule({
  declarations: [AppComponent, BackendLayoutComponent, SimpleLayoutComponent, P404Component],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ClarityModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    BsDropdownModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter,
        whitelistedDomains: [environment.apiHost]
      }
    })
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    AuthGuard,
    ClientDataService,
    ContractDataService,
    InvoiceDataService,
    StaffService,
    StaffDataService,
    GlobalService,
    SettingDataService,
    UserDataService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
