import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { GlobalService } from './global.service';
import { StaffService } from './staff.service';
import { ResponseBody } from '../model/response-body';
import { Invoice } from '../model/invoice';
import { InvoiceList } from '../model/invoice-list';
import { SharedService } from '../shared/shared.service';

@Injectable()
export class InvoiceDataService {

  constructor(private globalService: GlobalService, private staffService: StaffService, private http: HttpClient) { }

  public static getStatusTypes(): any[] {
    return [
      {
        label: 'Active',
        value: 1
      },
      {
        label: 'Disabled',
        value: 0
      }
    ];
  }

  public static getPaymodeTypes(): any[] {
    return [
      {
        label: 'Bank',
        value: 1
      },
      {
        label: 'Cash',
        value: 0
      }
    ];
  }

  // POST /v1/invoice
  addInvoice(invoice: Invoice): Observable<any> {
    const headers = GlobalService.getHeaders();

    return this.http.post<ResponseBody>(this.globalService.apiHost + '/invoice', JSON.stringify(invoice), { headers }).pipe(
      map((response) => {
        return response;
      }),
      catchError(err => GlobalService.handleError(err))
    );
  }

  // DELETE /v1/invoice/1
  deleteInvoiceById(id: number): Observable<any> {
    const headers = GlobalService.getHeaders();

    return this.http.delete<ResponseBody>(this.globalService.apiHost + '/invoice/' + id, { headers }).pipe(
      map((response) => {
        return response;
      }),
      catchError(err => GlobalService.handleError(err))
    );
  }

  // PUT /v1/invoice/1
  updateInvoiceById(invoice: Invoice): Observable<any> {
    const headers = GlobalService.getHeaders();

    return this.http.put<ResponseBody>(this.globalService.apiHost + '/invoice/' + invoice.id, { headers }).pipe(
      map((response) => {
        return response;
      }),
      catchError(err => GlobalService.handleError(err))
    );
  }

  // GET /v1/invoice
  getAllInvoices(extendedQueries?: any): Observable<InvoiceList> {
    const headers = GlobalService.getHeaders();

    let queries = {};
    if (extendedQueries) {
      queries = { ...queries, ...extendedQueries };
    }

    return this.http.get<ResponseBody>(this.globalService.apiHost + '/invoice?' + SharedService.serializeQueryString(queries), { headers }).pipe(
      map((response) => {
        return new InvoiceList(response.data);
      }),
      catchError(err => GlobalService.handleError(err))
    );
  }

  // GET /v1/invoice/1
  getInvoiceById(id: number): Observable<Invoice> {
    const headers = GlobalService.getHeaders();

    return this.http.get<ResponseBody>(this.globalService.apiHost + '/invoice/' + id, { headers }).pipe(
      map((response) => {
        return <Invoice>response.data;
      }),
      catchError(err => GlobalService.handleError(err))
    );
  }

  // GET /v1/invoice/emails/1
  sendEmailsByInvoiceId(id: number): Observable<boolean> {
    const headers = GlobalService.getHeaders();

    return this.http.get<ResponseBody>(this.globalService.apiHost + '/invoice/email?id=' + id, { headers }).pipe(
      map((response) => {
        return response.success;
      }),
      catchError(err => GlobalService.handleError(err))
    );
  }

}
