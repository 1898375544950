import { Component, OnInit } from '@angular/core';

import { StaffService } from './../service/staff.service';

@Component({
  selector: 'app-backend',
  templateUrl: './backend-layout.component.html',
})
export class BackendLayoutComponent implements OnInit {
  public userData: any = {};
  public today: Date;
  public collapsed: boolean;

  constructor(public staffService: StaffService) {
    this.today = new Date();
    this.collapsed = false;
  }

  ngOnInit(): void {
    const jwtValue: any = this.staffService.getJWTValue();
    if (jwtValue !== null) {
      this.userData = jwtValue.data;
    }
  }
}
