import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './service/auth.guard';

import { BackendLayoutComponent } from './layout/backend-layout.component';
import { SimpleLayoutComponent } from './layout/simple-layout.component';
import { P404Component } from './page/404.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '',
    component: BackendLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule) },
      { path: 'client', loadChildren: () => import('./client/client.module').then((m) => m.ClientModule) },
      { path: 'contract', loadChildren: () => import('./contract/contract.module').then((m) => m.ContractModule) },
      { path: 'invoice', loadChildren: () => import('./invoice/invoice.module').then((m) => m.InvoiceModule) },
      { path: 'staff', loadChildren: () => import('./staff/staff.module').then((m) => m.StaffModule) },
      { path: 'setting', loadChildren: () => import('./setting/setting.module').then((m) => m.SettingModule) },
      {
        path: 'setting',
        loadChildren: () => import('./setting/global/setting-global.module').then((m) => m.SettingGlobalModule),
      },
    ],
  },
  {
    path: '',
    component: SimpleLayoutComponent,
    children: [
      { path: 'login', loadChildren: () => import('./login/login.module').then((m) => m.LoginModule) },
      { path: 'logout', loadChildren: () => import('./logout/logout.module').then((m) => m.LogoutModule) },
    ],
  },
  // otherwise redirect to home
  { path: '**', component: P404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
