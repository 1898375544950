// tslint:disable-next-line: no-submodule-imports
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import { map, catchError } from 'rxjs/operators';

import {GlobalService} from './global.service';
import {StaffService} from './staff.service';
import {ResponseBody} from '../model/response-body';
import {Client} from '../model/client';
import {ClientList} from '../model/client-list';
import {SharedService} from '../shared/shared.service';

@Injectable()
export class ClientDataService {

    constructor(private globalService: GlobalService, private staffService: StaffService, private http: HttpClient) {}

    public static getStatusTypes(): any[] {
        return [
            {
                label: 'Active',
                value: 1
            },
            {
                label: 'Disabled',
                value: 0
            }
        ];
    }

    // POST /v1/Client
    addClient(client: Client): Observable<any> {
        const headers = GlobalService.getHeaders();

        return this.http.post<ResponseBody>(this.globalService.apiHost + '/client', JSON.stringify(client), { headers }).pipe(
            map((response) => {
                return response;
            }),
            catchError(err => GlobalService.handleError(err))
        );
    }

    // DELETE /v1/Client/1
    deleteClientById(id: number): Observable<any> {
        const headers = GlobalService.getHeaders();

        return this.http.delete<ResponseBody>(this.globalService.apiHost + '/client/'+id, { headers }).pipe(
            map((response) => {
                return response;
            }),
            catchError(err => GlobalService.handleError(err))
        );
    }

    // PUT /v1/Client/1
    updateClientById(client: Client): Observable<any> {
        const headers = GlobalService.getHeaders();

        return this.http.put<ResponseBody>(this.globalService.apiHost + '/client/'+client.id, JSON.stringify(client), { headers }).pipe(
            map((response) => {
                return response;
            }),
            catchError(err => GlobalService.handleError(err))
        );
    }

    // GET /v1/Client
    getAllClients(extendedQueries?: any): Observable<ClientList> {
        const headers = GlobalService.getHeaders();

        let queries = {};
        if (extendedQueries) {
            queries = { ...queries, ...extendedQueries };
        }

        return this.http.get<ResponseBody>(this.globalService.apiHost + '/client?'+SharedService.serializeQueryString(queries), { headers }).pipe(
            map((response) => { 
                return new ClientList(response.data);
            }),
            catchError(GlobalService.handleError)
        );
    }

    // GET /v1/Client/1
    getClientById(id: number): Observable<Client> {
        const headers = GlobalService.getHeaders();

        return this.http.get<ResponseBody>(this.globalService.apiHost + '/client/' + id, { headers }).pipe(
            map((response) => {
                return <Client>response.data;
            }),
            catchError(GlobalService.handleError)
        );
    }

}