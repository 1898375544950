import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { GlobalService } from './global.service';
import { StaffService } from './staff.service';
import { ResponseBody } from '../model/response-body';
import { Contract } from '../model/contract';
import { ContractList } from '../model/contract-list';
import { SharedService } from '../shared/shared.service';

@Injectable()
export class ContractDataService {

  constructor(private globalService: GlobalService, private staffService: StaffService, private http: HttpClient) { }

  public static getStatusTypes(): any[] {
    return [
      {
        label: 'Active',
        value: 1
      },
      {
        label: 'Disabled',
        value: 0
      }
    ];
  }

  // POST /v1/contract
  addContract(contract: Contract): Observable<any> {
    const headers = GlobalService.getHeaders();

    return this.http.post<ResponseBody>(this.globalService.apiHost + '/contract', JSON.stringify(contract), { headers }).pipe(
      map((response) => {
        return response;
      }),
      catchError(err => GlobalService.handleError(err))
    );
  }

  // DELETE /v1/contract/1
  deleteContractById(id: number): Observable<any> {
    const headers = GlobalService.getHeaders();

    return this.http.delete<ResponseBody>(this.globalService.apiHost + '/contract/' + id, { headers }).pipe(
      map((response) => {
        return response;
      }),
      catchError(err => GlobalService.handleError(err))
    );
  }

  // PUT /v1/contract/1
  updateContractById(contract: Contract): Observable<any> {
    const headers = GlobalService.getHeaders();

    return this.http.put<ResponseBody>(this.globalService.apiHost + '/contract/' + contract.id, JSON.stringify(contract), { headers }).pipe(
      map((response) => {
        return response;
      }),
      catchError(err => GlobalService.handleError(err))
    );
  }

  // GET /v1/contract
  getAllContracts(extendedQueries?: any): Observable<ContractList> {
    const headers = GlobalService.getHeaders();

    let queries = {};
    if (extendedQueries) {
      queries = { ...queries, ...extendedQueries };
    }

    return this.http.get<ResponseBody>(this.globalService.apiHost + '/contract?' + SharedService.serializeQueryString(queries), { headers }).pipe(
      map((response) => {
        return new ContractList(response.data);
      }),
      catchError(err => GlobalService.handleError(err))
    );
  }

  // GET /v1/contract/1
  getContractById(id: number): Observable<Contract> {
    const headers = GlobalService.getHeaders();

    return this.http.get<ResponseBody>(this.globalService.apiHost + '/contract/' + id, { headers }).pipe(
      map((response) => {
        return <Contract>response.data;
      }),
      catchError(err => GlobalService.handleError(err))
    );
  }

}
